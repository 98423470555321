import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { EmailSubscriptionStatus } from 'types/enums/EmailSubscriptionStatus';

import {
  getIsConfirmationProfilePopupShown,
  getIsConfirmationResent,
} from 'helpers/popups/emailConfirmation';
import { getUser, getUserEmailSubscriptionStatus } from 'store/auth/selectors';
import { getLastActiveUserProfileInfo } from 'store/profile/selectors';
import { getActiveSystemPopupTypeSelector } from 'store/systemPopup/selectors';
import {
  setActiveSystemPopup,
  SystemPopupTypes,
} from 'store/systemPopup/systemPopupSlice';

const PAGES_TO_IGNORE_CONFIRMATION = [
  '/dialogs',
  '/mails',
  '/upgrade',
  '/payment',
  '/wizard',
  '/user',
  '/disclosures-disclaimers',
  '/terms',
  '-policy',
];

// TODO base logic of PWA queue
// 1. schedule popup
// 2. if another popup active - reschedule popup ( ! use priority here )
// 3. create POPUP context and decompose existing logic
// 4. add priority level for every popup

export const usePopupsQueue = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const user = useSelector(getUser);
  const userEmailSubscriptionStatus = useSelector(
    getUserEmailSubscriptionStatus
  );
  const activeSystemPopupType = useSelector(getActiveSystemPopupTypeSelector);
  const lastActiveUserProfile = useSelector(getLastActiveUserProfileInfo);

  useEffect(() => {
    // ? MAIL CONFIRM POPUP TRIGGER START

    const isConfirmationDisabledByPage = PAGES_TO_IGNORE_CONFIRMATION.some(
      (pageItem) => pathname.includes(pageItem)
    );

    if (
      lastActiveUserProfile?.ulid_id &&
      lastActiveUserProfile?.ulid_id !== user?.ulid_id &&
      lastActiveUserProfile?.is_active &&
      userEmailSubscriptionStatus ===
        EmailSubscriptionStatus.ConfirmationSend &&
      !isConfirmationDisabledByPage &&
      !activeSystemPopupType &&
      !getIsConfirmationProfilePopupShown() &&
      !getIsConfirmationResent()
    ) {
      dispatch(
        setActiveSystemPopup({
          type: SystemPopupTypes.MailConfirmAfterProfile,
        })
      );
    }
  }, [
    activeSystemPopupType,
    dispatch,
    lastActiveUserProfile?.is_active,
    lastActiveUserProfile?.ulid_id,
    pathname,
    user?.ulid_id,
    userEmailSubscriptionStatus,
  ]);
};
