import { AppDispatch, AppThunk } from 'store';

import { MediaType } from 'types/enums/MediaType';
import { PresentStatus } from 'types/enums/presents/PresentStatus';

import { MediaApi } from 'api/MediaApi';
import { UserProfileApi } from 'api/UserProfileApi';
import { Funnel } from 'helpers/funnels/funnels';
import { removeFinderUser } from 'store/finder/finderSlice';
import { updateMailsPhotoSources } from 'store/mails/mailsSlice';
import {
  removeChatRequest,
  removeDialog,
  updateMessagesPhotoSources,
} from 'store/messenger/messengerSlice';
import {
  setActiveSystemPopup,
  SystemPopupTypes,
} from 'store/systemPopup/systemPopupSlice';

import {
  resetLastActiveUserProfileInfo,
  setContactMediaAccesses,
  setContactPresents,
  setIsContactPresentsLoading,
  setIsMediaAccessesLoading,
  setIsPresentsCategoriesLoading,
  setPresentsCategories,
  updateMediaAccess,
} from './profileSlice';

export const getContactMediaAccessesThunk =
  (payload: { contactId: string }) => async (dispatch: AppDispatch) => {
    dispatch(setIsMediaAccessesLoading(true));

    const { data: accessesData } = await MediaApi.getMediaAccesses(
      payload.contactId
    );

    dispatch(setIsMediaAccessesLoading(false));
    dispatch(
      setContactMediaAccesses({
        contactId: payload.contactId,
        accesses: accessesData.accesses,
        accessesByMediaType: accessesData.mass_accesses,
      })
    );
  };

export const grantMediaAccessThunk =
  (payload: {
    mediaId: number;
    mediaType: MediaType;
    successUrl: string;
    contactId: string;
    photoUrl: string;
    funnel: Funnel;
  }) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await MediaApi.grantMediaAccesses({
        media_id: payload.mediaId,
        media_type: payload.mediaType,
      });

      if (!data.media || !data.success) {
        dispatch(
          setActiveSystemPopup({
            type: SystemPopupTypes.LowBalance,
            params: {
              photoUrl: payload.photoUrl,
              url: payload.successUrl,
              type: 'media',
              funnel: payload.funnel,
              contactId: payload.contactId,
            },
          })
        );

        return;
      }

      if (payload.funnel === Funnel.MessagePhoto)
        dispatch(
          updateMessagesPhotoSources({
            contactId: payload.contactId,
            photos: [data.media],
          })
        );

      if (payload.funnel === Funnel.MailPhoto)
        dispatch(
          updateMailsPhotoSources({
            contactId: payload.contactId,
            photos: [data.media],
          })
        );

      dispatch(
        updateMediaAccess({
          contactId: payload.contactId,
          newMediaId: payload.mediaId,
          newMediaType: payload.mediaType,
        })
      );
    } catch (error) {
      dispatch(
        setActiveSystemPopup({
          type: SystemPopupTypes.LowBalance,
          params: {
            photoUrl: payload.photoUrl,
            url: payload.successUrl,
            type: 'media',
            funnel: payload.funnel,
            contactId: payload.contactId,
          },
        })
      );
    }
  };

export const reportUserProfileThunk =
  (payload: { userId: string; message: string }) =>
  async (dispatch: AppDispatch) => {
    await UserProfileApi.reportUser({
      userId: payload.userId,
      message: payload.message,
    });

    dispatch(removeDialog({ contactId: payload.userId }));
    dispatch(removeFinderUser({ usersId: payload.userId }));
    dispatch(removeChatRequest({ contactId: payload.userId }));
  };

export const blockUserProfileThunk =
  (payload: { userId: string }) => async (dispatch: AppDispatch) => {
    await UserProfileApi.blockUser({
      userId: payload.userId,
    });

    dispatch(resetLastActiveUserProfileInfo());
    dispatch(removeDialog({ contactId: payload.userId }));
    dispatch(removeFinderUser({ usersId: payload.userId }));
    dispatch(removeChatRequest({ contactId: payload.userId }));
  };

export const getPresentsCategoriesThunk =
  (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { presentsCategories } = getState().profile;

    if (presentsCategories.length) return;

    dispatch(setIsPresentsCategoriesLoading(true));

    const { data: presentsCategoriesResponse } =
      await UserProfileApi.getPresentsCategories();

    dispatch(setPresentsCategories(presentsCategoriesResponse));
    dispatch(setIsPresentsCategoriesLoading(false));
  };

export const getSentPresentsThunk =
  (payload: { contactId: string }) => async (dispatch: AppDispatch) => {
    dispatch(setIsContactPresentsLoading(true));

    const { sent, receiver } = await UserProfileApi.getSentPresents({
      contactId: payload.contactId,
    });

    const nonFailedPresents = sent.filter(
      (presentItem) => presentItem.status !== PresentStatus.Insuf
    );

    dispatch(
      setContactPresents({ sent: nonFailedPresents, contact: receiver })
    );
    dispatch(setIsContactPresentsLoading(false));
  };
