import { useCallback } from 'react';

import { MirrorService } from 'services/MirrorService';

import { TrackingApi } from 'api/TrackingApi';

export const usePWATracking = () => {
  const trackPWAInstall = useCallback((isSystemInstall: boolean) => {
    TrackingApi.trackPWAInstall({
      icon: `${window.location.origin}/favicons/${MirrorService.siteName}-32x32.png`,
      is_system: isSystemInstall ? 1 : 0,
    }).catch();
  }, []);

  return {
    trackPWAInstall,
  };
};
