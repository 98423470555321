import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { PopupName, PopupOperation } from 'types/enums/Popup';

import {
  getTrackingCookie,
  setTrackingCookies,
} from 'helpers/analytics/trackingCookies';
import {
  IS_PWA_ON_MAIL_CONFIRM_SHOWN,
  PWA_PARTNER_ID,
} from 'helpers/pwa/constants';
import {
  getPWATrackingCookies,
  isPWAMode,
  registerPWAServiceWorker,
  setPWATrackingCookies,
} from 'helpers/pwa/utils';
import { setSessionStorageItem } from 'helpers/sessionStorage';
import { assertNotNullObject } from 'helpers/utils/checkType';
import { usePopupTracking } from 'hooks/tracking/usePopupTracking';
import { usePWATracking } from 'hooks/tracking/usePWATracking';
import { useSystemSearchQueries } from 'hooks/useSystemSearchQueries';
import { getIsAuthenticated } from 'store/auth/selectors';

export interface ShowNativePromptDialogPayload {
  popupName: PopupName;
  onShow?: () => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  onError?: (error: any) => void;
}

interface PWAContextValue {
  isCanPromotePWA: boolean;
  handlePWAInstalled: () => void;
  handleShowNativePromptDialogPWA: (
    payload: ShowNativePromptDialogPayload
  ) => void;
}

export const PWAContext = createContext({} as PWAContextValue);

interface DeferredPwaEvent {
  prompt: () => any;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

export const PWAContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const isAuthenticated = useSelector(getIsAuthenticated);

  const { partnerLoginIdQuery } = useSystemSearchQueries();
  const { trackPWAInstall } = usePWATracking();
  const { trackPopup } = usePopupTracking();

  const [isCanPromotePWA, setIsCanPromotePWA] = useState(false);
  const [isSystemInstall, setIsSystemInstall] = useState(true);

  const deferredEvent = useRef<DeferredPwaEvent | null>(null);

  const handlePWAInstalled = useCallback(() => {
    setIsCanPromotePWA(false);
  }, []);

  const handleShowNativePromptDialogPWA = useCallback(
    async (payload: ShowNativePromptDialogPayload) => {
      if (!assertNotNullObject(deferredEvent.current)) {
        throw new Error('deferredPwaInstall is not object');
      }

      if (
        !deferredEvent.current.prompt ||
        typeof deferredEvent.current.prompt !== 'function'
      ) {
        throw new Error('deferredPwaInstall have to have propmt property');
      }

      if (!deferredEvent.current.userChoice) {
        throw new Error('deferredPwaInstall have to have userChoice property');
      }

      deferredEvent.current.prompt();
      payload.onShow && payload.onShow();

      trackPopup({
        popupName: payload.popupName,
        operation: PopupOperation.Show,
      });

      setSessionStorageItem(IS_PWA_ON_MAIL_CONFIRM_SHOWN, true);
      setIsSystemInstall(false);

      try {
        const result = await deferredEvent.current.userChoice;

        if (result?.outcome === 'dismissed') {
          trackPopup({
            popupName: payload.popupName,
            operation: PopupOperation.Close,
          });

          payload.onCancel && payload.onCancel();
        }

        if (result?.outcome === 'accepted') {
          const trackingCookies = getTrackingCookie();

          if (trackingCookies) {
            setPWATrackingCookies(trackingCookies);
          }

          trackPopup({
            popupName: payload.popupName,
            operation: PopupOperation.Click,
          });

          payload.onSubmit && payload.onSubmit();
        }
      } catch (error: any) {
        payload.onError && payload.onError(error);
      }
    },
    [trackPopup]
  );

  const handleBeforeInstallPrompt = useCallback((e: Event) => {
    // @ts-ignore
    deferredEvent.current = e;

    setIsCanPromotePWA(true);
  }, []);

  const handleSuccessInstall = useCallback(() => {
    trackPWAInstall(isSystemInstall);

    setIsSystemInstall(true);
  }, [isSystemInstall, trackPWAInstall]);

  const value = useMemo(
    () => ({
      isCanPromotePWA,
      handlePWAInstalled,
      handleShowNativePromptDialogPWA,
    }),
    [handlePWAInstalled, handleShowNativePromptDialogPWA, isCanPromotePWA]
  );

  useEffect(() => {
    const pwaTrackingCookies = getPWATrackingCookies();

    if (
      Number(partnerLoginIdQuery.value) === PWA_PARTNER_ID &&
      pwaTrackingCookies
    ) {
      setTrackingCookies(pwaTrackingCookies);
    }
  }, [partnerLoginIdQuery.value]);

  useEffect(() => {
    if (isAuthenticated && !isPWAMode()) {
      registerPWAServiceWorker();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );
    };
  }, [handleBeforeInstallPrompt]);

  useEffect(() => {
    window.addEventListener('appinstalled', handleSuccessInstall);

    return () => {
      window.removeEventListener('appinstalled', handleSuccessInstall);
    };
  }, [handleSuccessInstall]);

  return <PWAContext.Provider value={value}>{children}</PWAContext.Provider>;
};
