import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConversionEventType } from 'types/enums/ConversionEvent';

import { ConversionsApi } from 'api/ConversionsApi';
import { FacebookPixel } from 'helpers/analytics/fbPixel';
import { Gtm } from 'helpers/analytics/gtm';
import { updateInitializedPixelsIds } from 'store/common/commonSlice';
import { getInitializedPixelsIds } from 'store/common/selectors';

//  ! do not use useEffect here
export const useConversionTracking = () => {
  const dispatch = useDispatch();

  const initializedPixelsIds = useSelector(getInitializedPixelsIds);

  const sendFBConversion = useCallback(
    (event: any & { isRetry: boolean }) => {
      const conversionPixelId = event?.data?.pixel_id;
      const eventID = event?.data?.event_id;
      const isRetry = event?.isRetry;

      // ? disable tracking for iframe)
      if (window.location !== window.parent?.location) return;

      if (!conversionPixelId || !eventID) {
        return;
      }

      const isPixelNotInitialized =
        !initializedPixelsIds.includes(conversionPixelId);

      if (isPixelNotInitialized)
        FacebookPixel.init({
          newPixelId: conversionPixelId,
        });

      FacebookPixel.trackEvent({ eventData: event.data, isRetry });

      if (isPixelNotInitialized)
        dispatch(updateInitializedPixelsIds(conversionPixelId));
    },
    [dispatch, initializedPixelsIds]
  );

  const sendGTMConversion = useCallback((event: any) => {
    // ? disable tracking for iframe)
    if (window.location !== window.parent?.location) return;

    Gtm.trackConversionEventByName(event.data);
  }, []);

  const conversionEventHandler = useCallback(
    (event: any) => {
      sendFBConversion(event);
      sendGTMConversion(event);
    },
    [sendFBConversion, sendGTMConversion]
  );

  const sendSingleFacebookConversionHandler = useCallback(
    async ({
      eventType,
      isRetry,
    }: {
      eventType: ConversionEventType;
      isRetry?: boolean;
    }) => {
      try {
        const eventData = await ConversionsApi.createConversionData(eventType);

        sendFBConversion({ data: eventData, isRetry: isRetry || false });
      } catch (error) {
        console.error('Wrong pixel setup');
      }
    },
    [sendFBConversion]
  );

  const sendSingleGtmConversionHandler = useCallback(
    ({
      eventType,
      isRetry,
    }: {
      eventType: ConversionEventType;
      isRetry?: boolean;
    }) => {
      Gtm.trackConversionEventByType({
        eventType,
        isRetry: isRetry || false,
      });
    },
    []
  );

  return {
    conversionEventHandler,
    sendSingleFacebookConversionHandler,
    sendSingleGtmConversionHandler,
  };
};
