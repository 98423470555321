import { createSelector } from '@reduxjs/toolkit';

import { Present } from 'types/interfaces/presents/Present';

import { RootState } from 'store/rootReducer';

export const getLastActiveUserProfileInfo = (state: RootState) =>
  state.profile.lastActiveUserProfileInfo;

export const getIsMediaAccessesLoading = (state: RootState) =>
  state.profile.isMediaAccessesLoading;

export const getMediaAccesses = (contactId?: string) => (state: RootState) => {
  return contactId ? state.profile.mediaAccesses[contactId]?.accesses : [];
};

export const getMediaAccessesByMediaType =
  (contactId?: string) => (state: RootState) => {
    return contactId
      ? state.profile.mediaAccesses[contactId]?.accessesByMediaType
      : [];
  };

export const getPresentsCategoriesSelector = (state: RootState) =>
  state.profile.presentsCategories;

export const getIsPresentsCategoriesLoadingSelector = (state: RootState) =>
  state.profile.isPresentsCategoriesLoading;

export const getContactPresentsSelector = (state: RootState) =>
  state.profile.contactPresents;

export const getIsContactPresentsLoadingSelector = (state: RootState) =>
  state.profile.isContactPresentsLoading;

export const getPresentByIdSelector = (presentId: number) =>
  createSelector(getPresentsCategoriesSelector, (presentsCategories) => {
    if (!presentsCategories.length) return null;

    return presentsCategories
      .reduce<Present[]>((acc, categoryItem) => {
        return [...acc, ...categoryItem.gifts];
      }, [])
      .find((presentItem) => presentItem.id === presentId);
  });
