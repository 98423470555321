import React, { memo, useCallback, useEffect } from 'react';

import { PopupName, PopupOperation } from 'types/enums/Popup';

import { IS_PWA_ON_MAIL_CONFIRM_SHOWN } from 'helpers/pwa/constants';
import { setSessionStorageItem } from 'helpers/sessionStorage';
import { usePopupTracking } from 'hooks/tracking/usePopupTracking';
import { useDeleteQueryByName } from 'hooks/useDeleteQueryByName';
import { usePromotionPWA } from 'hooks/usePromotionPWA';
import { QueryKeys } from 'hooks/useSystemSearchQueries';
import { PWAPopupParams } from 'store/systemPopup/systemPopupSlice';

import { Default, Mobile } from 'components/shared/MediaComponents';
import { Popup } from 'components/shared/Popup';
import { PopupMobile } from 'components/shared/PopupMobile';

import { PromotePopupContent } from './PromotePopupContent';
import { WizardPopupContent } from './WizardPopupContent';

interface Props {
  params: PWAPopupParams;
  onClose: () => void;
}

export const PromotePWAPopup: React.FC<Props> = memo(({ params, onClose }) => {
  const { handlePWAPromotionButtonSubmit } = usePromotionPWA();
  const { trackPopup } = usePopupTracking();

  const { deleteQueryByName } = useDeleteQueryByName();

  const handleClose = useCallback(() => {
    if (params.isWizard) {
      setSessionStorageItem(IS_PWA_ON_MAIL_CONFIRM_SHOWN, true);
    }

    deleteQueryByName(QueryKeys.StarterPopup);

    onClose();
  }, [deleteQueryByName, onClose, params.isWizard]);

  const handleSubmit = useCallback(() => {
    trackPopup({
      popupName: params.popupName,
      operation: PopupOperation.Click,
    });

    handlePWAPromotionButtonSubmit(PopupName.PWASystem);
    handleClose();
  }, [
    handleClose,
    handlePWAPromotionButtonSubmit,
    params.popupName,
    trackPopup,
  ]);

  useEffect(() => {
    trackPopup({
      popupName: params.popupName,
      operation: PopupOperation.Show,
    });

    return () => {
      trackPopup({
        popupName: params.popupName,
        operation: PopupOperation.Close,
      });
    };
  }, [params.popupName, trackPopup]);

  if (params.isWizard) {
    return (
      <Popup onClose={handleClose} isInline>
        <WizardPopupContent onSubmit={handleSubmit} />
      </Popup>
    );
  }

  return (
    <>
      <Mobile>
        <PopupMobile isOpen onClose={handleClose}>
          <PromotePopupContent onSubmit={handleSubmit} onClose={handleClose} />
        </PopupMobile>
      </Mobile>

      <Default>
        <Popup isIndented={false} onClose={handleClose}>
          <PromotePopupContent onSubmit={handleSubmit} onClose={handleClose} />
        </Popup>
      </Default>
    </>
  );
});
