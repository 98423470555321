import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { AuthApi } from 'api/AuthApi';
import { getIsCommonStateLoaded } from 'store/auth/selectors';

const PING_TIMEOUT = 60 * 1000;

export const useEuPing = (isAuthenticated: boolean) => {
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const isCommonStateLoaded = useSelector(getIsCommonStateLoaded);

  const pingEU = useCallback(async () => {
    if (!isAuthenticated || !isCommonStateLoaded) return null;

    AuthApi.pingOnlineEU();

    timeoutId.current = setTimeout(pingEU, PING_TIMEOUT);

    return null;
  }, [isAuthenticated, isCommonStateLoaded]);

  useEffect(() => {
    pingEU();

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [pingEU]);
};
