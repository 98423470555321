import React, {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Centrifuge, ErrorContext, Subscription } from 'centrifuge';

import { MirrorService } from 'services/MirrorService';

import { DialogsApi } from 'api/DialogsApi';
import { TrackingApi } from 'api/TrackingApi';
import { getAccessTokenDataFromStorage } from 'helpers/auth';
import { useUserId } from 'hooks/useUserId';
import { useWebSocketHandlers } from 'hooks/useWebSocketHandlers';
import { getIsAuthenticated } from 'store/auth/selectors';

enum WSChannel {
  PaymentOrder = 'payment_order',
  Messages = 'messages',
  Notifications = 'notifications',
  Conversion = 'conversion',
  Mails = 'inmails',
  System = 'system',
  MediaAccess = 'media_access',
  Presents = 'real_gift',
}

interface WebSocketSubscriptions {
  messageSubscription: Subscription | null;
  paymentOrderSubscriptions: Subscription | null;
  notificationSubscription: Subscription | null;
  conversionSubscription: Subscription | null;
  mailsSubscription: Subscription | null;
  systemSubscription: Subscription | null;
  mediaAccessSubscription: Subscription | null;
  presentsSubscription: Subscription | null;
}

interface WebSocketContextValue extends WebSocketSubscriptions {
  wsCheckIds: MutableRefObject<string[]> | null;
}

export const WebSocketContext = createContext<WebSocketContextValue>({
  wsCheckIds: null,
  messageSubscription: null,
  paymentOrderSubscriptions: null,
  notificationSubscription: null,
  conversionSubscription: null,
  mailsSubscription: null,
  systemSubscription: null,
  mediaAccessSubscription: null,
  presentsSubscription: null,
});

const getToken = async () => {
  if (!getAccessTokenDataFromStorage().token) {
    return '';
  }

  try {
    const { token } = await DialogsApi.fetchWSConnectionToken();

    return token;
  } catch (error) {
    throw new Error(`Unexpected status code from getToken: ${error}`);
  }
};

export const WebSocketProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { userId } = useUserId();

  const lastTrackedError = useRef<string | null>(null);
  const isAuthenticated = useSelector(getIsAuthenticated);

  const [isConnected, setIsConnected] = useState(false);

  const centrifuge = useMemo(() => {
    if (!isAuthenticated || !userId) return null;

    return new Centrifuge(`${MirrorService.wsUrl}/connection/websocket`, {
      getToken,
    });
  }, [isAuthenticated, userId]);

  const {
    wsCheckIds,
    messageHandler,
    notificationsHandler,
    conversionHandler,
    mailsHandler,
    systemHandler,
    mediaAccessHandler,
    presentsHandler,
  } = useWebSocketHandlers();

  const {
    messageSubscription,
    paymentOrderSubscriptions,
    notificationSubscription,
    conversionSubscription,
    mailsSubscription,
    systemSubscription,
    mediaAccessSubscription,
    presentsSubscription,
  } = useMemo<WebSocketSubscriptions>(() => {
    if (!centrifuge)
      return {
        messageSubscription: null,
        paymentOrderSubscriptions: null,
        notificationSubscription: null,
        conversionSubscription: null,
        mailsSubscription: null,
        systemSubscription: null,
        mediaAccessSubscription: null,
        presentsSubscription: null,
      };

    return {
      messageSubscription: centrifuge.newSubscription(
        `${WSChannel.Messages}#${userId}`
      ),
      paymentOrderSubscriptions: centrifuge.newSubscription(
        `${WSChannel.PaymentOrder}#${userId}`
      ),
      notificationSubscription: centrifuge.newSubscription(
        `${WSChannel.Notifications}#${userId}`
      ),
      conversionSubscription: centrifuge.newSubscription(
        `${WSChannel.Conversion}#${userId}`
      ),
      mailsSubscription: centrifuge.newSubscription(
        `${WSChannel.Mails}#${userId}`
      ),
      systemSubscription: centrifuge.newSubscription(
        `${WSChannel.System}#${userId}`
      ),
      mediaAccessSubscription: centrifuge.newSubscription(
        `${WSChannel.MediaAccess}#${userId}`
      ),
      presentsSubscription: centrifuge.newSubscription(
        `${WSChannel.Presents}#${userId}`
      ),
    };
  }, [centrifuge, userId]);

  const contextValue = useMemo<WebSocketContextValue>(
    () => ({
      wsCheckIds,
      messageSubscription,
      paymentOrderSubscriptions,
      notificationSubscription,
      conversionSubscription,
      mailsSubscription,
      systemSubscription,
      mediaAccessSubscription,
      presentsSubscription,
    }),
    [
      wsCheckIds,
      messageSubscription,
      paymentOrderSubscriptions,
      notificationSubscription,
      conversionSubscription,
      mailsSubscription,
      systemSubscription,
      mediaAccessSubscription,
      presentsSubscription,
    ]
  );

  const clearCentrifugeSubscriptions = useCallback(() => {
    messageSubscription?.unsubscribe();
    paymentOrderSubscriptions?.unsubscribe();
    notificationSubscription?.unsubscribe();
    conversionSubscription?.unsubscribe();
    mailsSubscription?.unsubscribe();
    systemSubscription?.unsubscribe();
    mediaAccessSubscription?.unsubscribe();
    presentsSubscription?.unsubscribe();

    centrifuge?.removeSubscription(messageSubscription);
    centrifuge?.removeSubscription(paymentOrderSubscriptions);
    centrifuge?.removeSubscription(notificationSubscription);
    centrifuge?.removeSubscription(conversionSubscription);
    centrifuge?.removeSubscription(mailsSubscription);
    centrifuge?.removeSubscription(systemSubscription);
    centrifuge?.removeSubscription(mediaAccessSubscription);
    centrifuge?.removeSubscription(presentsSubscription);

    centrifuge?.removeAllListeners();
    centrifuge?.disconnect();

    setIsConnected(false);
  }, [
    messageSubscription,
    paymentOrderSubscriptions,
    notificationSubscription,
    conversionSubscription,
    mailsSubscription,
    systemSubscription,
    mediaAccessSubscription,
    presentsSubscription,
    centrifuge,
  ]);

  const handleWsLoadSuccess = useCallback(() => {
    TrackingApi.trackWsLoadSuccess();
  }, []);

  const handleWsLoadError = useCallback((ctx: ErrorContext) => {
    const error = `${ctx?.error?.code}-${ctx?.error?.message}`;

    if (error !== lastTrackedError.current) {
      lastTrackedError.current = error;

      TrackingApi.trackWsLoadError(error);
    }
  }, []);

  useEffect(() => {
    if (centrifuge && isAuthenticated && !isConnected) {
      centrifuge.connect();

      setIsConnected(true);
    }
  }, [centrifuge, isAuthenticated, isConnected]);

  useEffect(() => {
    if (!!centrifuge && isConnected) {
      centrifuge.on('connected', handleWsLoadSuccess);
      centrifuge.on('error', handleWsLoadError);
    }

    return () => {
      centrifuge?.removeListener('connected', handleWsLoadSuccess);
      centrifuge?.removeListener('error', handleWsLoadError);
    };
  }, [centrifuge, handleWsLoadError, handleWsLoadSuccess, isConnected]);

  useEffect(() => {
    messageSubscription?.subscribe();
    paymentOrderSubscriptions?.subscribe();
    notificationSubscription?.subscribe();
    conversionSubscription?.subscribe();
    mailsSubscription?.subscribe();
    systemSubscription?.subscribe();
    mediaAccessSubscription?.subscribe();
    presentsSubscription?.subscribe();

    return () => {
      messageSubscription?.unsubscribe();
      paymentOrderSubscriptions?.unsubscribe();
      notificationSubscription?.unsubscribe();
      conversionSubscription?.unsubscribe();
      mailsSubscription?.unsubscribe();
      systemSubscription?.unsubscribe();
      mediaAccessSubscription?.unsubscribe();
      presentsSubscription?.unsubscribe();
    };
  }, [
    centrifuge,
    conversionSubscription,
    messageSubscription,
    notificationSubscription,
    paymentOrderSubscriptions,
    mailsSubscription,
    systemSubscription,
    mediaAccessSubscription,
    presentsSubscription,
  ]);

  useEffect(() => {
    if (isAuthenticated === false) {
      clearCentrifugeSubscriptions();
    }
  }, [clearCentrifugeSubscriptions, isAuthenticated]);

  useEffect(() => {
    messageSubscription?.on('publication', messageHandler);

    return () => {
      messageSubscription?.removeListener('publication', messageHandler);
    };
  }, [messageHandler, messageSubscription]);

  useEffect(() => {
    notificationSubscription?.on('publication', notificationsHandler);

    return () => {
      notificationSubscription?.removeListener(
        'publication',
        notificationsHandler
      );
    };
  }, [notificationSubscription, notificationsHandler]);

  useEffect(() => {
    conversionSubscription?.on('publication', conversionHandler);

    return () => {
      conversionSubscription?.removeListener('publication', conversionHandler);
    };
  }, [conversionHandler, conversionSubscription]);

  useEffect(() => {
    mailsSubscription?.on('publication', mailsHandler);

    return () => {
      mailsSubscription?.removeListener('publication', mailsHandler);
    };
  }, [mailsHandler, mailsSubscription]);

  useEffect(() => {
    systemSubscription?.on('publication', systemHandler);

    return () => {
      systemSubscription?.removeListener('publication', systemHandler);
    };
  }, [systemHandler, systemSubscription]);

  useEffect(() => {
    mediaAccessSubscription?.on('publication', mediaAccessHandler);

    return () => {
      mediaAccessSubscription?.removeListener(
        'publication',
        mediaAccessHandler
      );
    };
  }, [mediaAccessHandler, mediaAccessSubscription]);

  useEffect(() => {
    presentsSubscription?.on('publication', presentsHandler);

    return () => {
      presentsSubscription?.removeListener('publication', presentsHandler);
    };
  }, [presentsHandler, presentsSubscription]);

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};
