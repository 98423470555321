import { DeepPartial } from 'types/DeepPartial';
import { PresentCategory } from 'types/interfaces/presents/PresentCategory';
import { PresentTrackInfo } from 'types/interfaces/presents/PresentTrackInfo';
import { User } from 'types/interfaces/user/User';
import { UserContact } from 'types/interfaces/user/UserContact';
import { UserEditOptions } from 'types/interfaces/user/UserDetails';
import { UserProfile } from 'types/interfaces/user/UserProfile';
import { Video } from 'types/interfaces/Video';

import { httpClient } from './httpClient';

type UserEditPayload = DeepPartial<
  Pick<User, 'name' | 'age' | 'details'> & { city: number }
>;

interface UserOptionsResponse {
  options: UserEditOptions;
}

interface UserEditResponse {
  user: User;
  options: UserEditOptions;
}

interface ReportUserPayload {
  userId: string;
  message: string;
}

interface BlockUserPayload {
  userId: string;
}

interface SentPresentsPayload {
  contactId: string;
}

interface SentPresentsResponse {
  sent: PresentTrackInfo[];
  receiver: UserContact;
}

interface SendPresentPayload {
  contactId: string;
  presentId: number;
  presentText: string;
}

export interface UserVideosResponse {
  videos: Video[];
  videos_access: number[];
}

export interface ChangeUserLanguagePayload {
  lang: string;
}

export interface ChangeUserLanguageResponse {
  site_language: string;
}

export const UserProfileApi = {
  async fetchPublicProfile(payload: { userId: string }) {
    const { data } = await httpClient.get<UserProfile>(
      `/profile/${payload.userId}`
    );

    return data;
  },

  async fetchUserEditOptions() {
    const { data } = await httpClient.get<UserOptionsResponse>('/user');

    return data;
  },

  async editUserInfo(payload: UserEditPayload) {
    const { data } = await httpClient.put<UserEditResponse>(
      '/user/edit',
      payload
    );

    return data;
  },

  async setMainPhoto(payload: { photoId: number }) {
    return httpClient.get(`/user/photo/set-main/${payload.photoId}`);
  },

  async deletePhoto(payload: { photoId: number }) {
    return httpClient.delete(`/user/photo/${payload.photoId}`);
  },

  async deleteVideo(payload: { videoId: number }) {
    return httpClient.delete(`/user/video/${payload.videoId}`);
  },

  async fetchUserVideos(payload: { userId: string }) {
    const { data } = await httpClient.get<UserVideosResponse>(
      `/user/${payload.userId}/videos`
    );

    return data;
  },

  async fetchUserVideo(payload: { videoId: number }) {
    const { data } = await httpClient.get<Video>(`/videos/${payload.videoId}`);

    return data;
  },

  async changeUserLanguage(payload: ChangeUserLanguagePayload) {
    const { data } = await httpClient.patch<ChangeUserLanguageResponse>(
      '/user/settings/site-language',
      {
        site_language: payload.lang,
      }
    );

    return data;
  },

  async reportUser(payload: ReportUserPayload) {
    const { data } = await httpClient.post('/profile/add-report', {
      blocked_user_ulid: payload.userId,
      message: payload.message,
    });

    return data;
  },

  async blockUser(payload: BlockUserPayload) {
    const { data } = await httpClient.post('/profile/add-block', {
      blocked_user_ulid: payload.userId,
    });

    return data;
  },

  async getBlockedUsers() {
    const { data } = await httpClient.get('/profile/get-blocked');

    return data.data;
  },

  async unblockUser(payload: BlockUserPayload) {
    const { data } = await httpClient.post('/profile/unblock', {
      blocked_user_ulid: payload.userId,
    });

    return data.data;
  },

  async getPresentsCategories() {
    const { data } = await httpClient.get<{ data: PresentCategory[] }>(
      '/real-gift'
    );

    return data;
  },

  async getSentPresents(payload: SentPresentsPayload) {
    const { data } = await httpClient.get<SentPresentsResponse>(
      `/real-gift/sent-list/${payload.contactId}`
    );

    return data;
  },

  async sendPresent(payload: SendPresentPayload) {
    const { data } = await httpClient.post('/real-gift/send-gift', {
      receiver_id: payload.contactId,
      real_gift_id: payload.presentId,
      gift_card: payload.presentText,
    });

    return data;
  },
};
