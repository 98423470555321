import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PopupName, PopupOperation } from 'types/enums/Popup';

import { usePopupTracking } from 'hooks/tracking/usePopupTracking';
import { useDeleteQueryByName } from 'hooks/useDeleteQueryByName';
import { QueryKeys } from 'hooks/useSystemSearchQueries';
import { getCreditsCurrencySelector } from 'store/payment/selectors';
import { InitCreditsPopupParams } from 'store/systemPopup/systemPopupSlice';

import { ReactComponent as CreditsIcon } from 'assets/icons/welcome-credits.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { Popup } from 'components/shared/Popup';

import css from './initCreditsPopup.module.sass';

interface Props {
  params: InitCreditsPopupParams;
  onClose: () => void;
}

export const InitCreditsPopup: React.FC<Props> = ({ params, onClose }) => {
  const creditsCurrency = useSelector(getCreditsCurrencySelector);

  const { trackPopup } = usePopupTracking();

  const { deleteQueryByName } = useDeleteQueryByName();

  const handleClose = useCallback(() => {
    deleteQueryByName(QueryKeys.StarterPopup);
    onClose();
  }, [deleteQueryByName, onClose]);

  const handleClick = useCallback(() => {
    trackPopup({
      popupName: PopupName.InitFreeCredits,
      operation: PopupOperation.Click,
    });

    handleClose();
  }, [handleClose, trackPopup]);

  useEffect(() => {
    trackPopup({
      popupName: PopupName.InitFreeCredits,
      operation: PopupOperation.Show,
    });

    return () => {
      trackPopup({
        popupName: PopupName.InitFreeCredits,
        operation: PopupOperation.Close,
      });
    };
  }, [trackPopup]);

  return (
    <Popup onClose={handleClose}>
      <div className={css.root}>
        <CreditsIcon className={css.icon} />
        <h2 className={css.title}>Hi {params?.username || ''}!</h2>
        <p className={css.text}>
          You received 20 free {creditsCurrency}. <br />
          Use them to chat with those you like.
        </p>
        <BaseButton
          className={css.btn}
          type={ButtonTypes.Accent}
          onClick={handleClick}
        >
          Find My Love
        </BaseButton>
      </div>
    </Popup>
  );
};
