import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { EmailSubscriptionStatus } from 'types/enums/EmailSubscriptionStatus';
import { PopupName, PopupOperation } from 'types/enums/Popup';

import { IS_PWA_ON_MAIL_CONFIRM_SHOWN } from 'helpers/pwa/constants';
import { getSessionStorageItem } from 'helpers/sessionStorage';
import { usePopupTracking } from 'hooks/tracking/usePopupTracking';
import { useMedia } from 'hooks/useMedia';
import { usePromotionPWA } from 'hooks/usePromotionPWA';
import {
  getUserEmail,
  getUserEmailSubscriptionStatus,
} from 'store/auth/selectors';
import { closeConfirmMailFromNotificationPopup } from 'store/common/commonSlice';
import { getIsConfirmationNotificationShownSelector } from 'store/common/selectors';

import { ReactComponent as ConfirmationSendIcon } from 'assets/icons/confirmation-send.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';

import fadeTransition from 'styles/transitions/fade.module.sass';

import css from './mailConfirmationNotification.module.sass';

const PAGES_TO_IGNORE_CONFIRMATION_PATTERN = [
  '/dialogs',
  '/mails',
  '/upgrade',
  '/payment',
  '/wizard',
  '/my-profile/setting',
];

const PAGES_TO_IGNORE_CONFIRMATION_EXACT = [
  '/my-profile',
  '/my-profile/contact-us',
  '/my-profile/help-center',
  '/tracking-policy',
  '/terms',
  '/privacy-policy',
  '/refund-policy',
  '/anti-scam-policy',
  '/disclosures-disclaimers',
];

export const MailConfirmationNotification: React.FC = memo(() => {
  const { isMobile } = useMedia();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { trackPopup } = usePopupTracking();
  const { isCanPromotePWA, handlePWAPromotionButtonSubmit } = usePromotionPWA();

  const isConfirmationNotificationShown = useSelector(
    getIsConfirmationNotificationShownSelector
  );
  const userEmail = useSelector(getUserEmail);
  const userEmailSubscriptionStatus = useSelector(
    getUserEmailSubscriptionStatus
  );

  const handleSubmit = useCallback(() => {
    const isPWAOnMailConfirmShown = getSessionStorageItem(
      IS_PWA_ON_MAIL_CONFIRM_SHOWN
    );

    dispatch(closeConfirmMailFromNotificationPopup());

    trackPopup({
      popupName: PopupName.ConfirmationNotification,
      operation: PopupOperation.ClickAccept,
    });

    if (!isPWAOnMailConfirmShown && isCanPromotePWA) {
      handlePWAPromotionButtonSubmit(PopupName.PWAAfterRemainder);
    }
  }, [dispatch, handlePWAPromotionButtonSubmit, isCanPromotePWA, trackPopup]);

  const isNotificationDisabledByPage = useMemo(
    () =>
      PAGES_TO_IGNORE_CONFIRMATION_PATTERN.some((pageItem) =>
        pathname.includes(pageItem)
      ) ||
      PAGES_TO_IGNORE_CONFIRMATION_EXACT.some(
        (pageItem) => pathname === pageItem
      ),
    [pathname]
  );

  const shouldHideConfirmationPopup = useMemo(
    () =>
      userEmailSubscriptionStatus !==
        EmailSubscriptionStatus.ConfirmationSend ||
      isConfirmationNotificationShown ||
      isNotificationDisabledByPage ||
      !isMobile ||
      !userEmail,
    [
      isMobile,
      isNotificationDisabledByPage,
      isConfirmationNotificationShown,
      userEmail,
      userEmailSubscriptionStatus,
    ]
  );

  useEffect(() => {
    if (!shouldHideConfirmationPopup)
      trackPopup({
        popupName: PopupName.ConfirmationNotification,
        operation: PopupOperation.Show,
      });

    return () => {
      if (!shouldHideConfirmationPopup)
        trackPopup({
          popupName: PopupName.ConfirmationNotification,
          operation: PopupOperation.Close,
        });
    };
  }, [shouldHideConfirmationPopup, trackPopup]);

  if (shouldHideConfirmationPopup) return null;

  return (
    <CSSTransition in appear timeout={1500} classNames={fadeTransition}>
      <div className={css.root}>
        <div className={css.container}>
          <ConfirmationSendIcon className={css.icon} />

          <div className={css.content}>
            <p className={css.title}>Please confirm your email address</p>
            <p className={css.email}>{userEmail || '---'}</p>
          </div>

          <BaseButton
            className={css.button}
            type={ButtonTypes.OutlinePrimary}
            onClick={handleSubmit}
          >
            OK
          </BaseButton>
        </div>
      </div>
    </CSSTransition>
  );
});
