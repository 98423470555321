import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import { Pages } from 'types/enums/Pages';

import { useMedia } from 'hooks/useMedia';

import { AppRoute } from 'components/shared/AppRoute';

const SettingsMobile = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingsMobile" */ 'components/sections/MyProfile/Settings/SettingsMobile'
    ),
  {
    resolveComponent: (component) => component.SettingsMobile,
  }
);

const NotificationsSettings = loadable(
  () =>
    import(
      /* webpackChunkName: "NotificationsSettings" */ 'components/sections/MyProfile/Settings/NotificationsSettings'
    ),
  {
    resolveComponent: (component) => component.NotificationsSettings,
  }
);

const DeleteAccount = loadable(
  () =>
    import(
      /* webpackChunkName: "DeleteAccount" */ 'components/sections/MyProfile/Settings/DeleteAccount'
    ),
  {
    resolveComponent: (component) => component.DeleteAccount,
  }
);

const ChangePassword = loadable(
  () =>
    import(
      /* webpackChunkName: "ChangePassword" */ 'components/sections/MyProfile/Settings/ChangePassword'
    ),
  {
    resolveComponent: (component) => component.ChangePassword,
  }
);

const ChangeEmail = loadable(
  () =>
    import(
      /* webpackChunkName: "ChangeEmail" */ 'components/sections/MyProfile/Settings/ChangeEmail'
    ),
  {
    resolveComponent: (component) => component.ChangeEmail,
  }
);

const AccountSettings = loadable(
  () =>
    import(
      /* webpackChunkName: "AccountSettings" */ 'components/sections/MyProfile/Settings/AccountSettings'
    ),
  {
    resolveComponent: (component) => component.AccountSettings,
  }
);

const BlockedAndHiddenProfile = loadable(
  () =>
    import(
      /* webpackChunkName: "BlockedProfile" */ 'components/sections/MyProfile/BlockAndHide/BlockedAndHiddenPage'
    ),
  {
    resolveComponent: (component) => component.BlockedAndHiddenPage,
  }
);

const BlockedProfile = loadable(
  () =>
    import(
      /* webpackChunkName: "BlockedProfile" */ 'components/sections/MyProfile/BlockAndHide/BlockedProfile'
    ),
  {
    resolveComponent: (component) => component.BlockedProfile,
  }
);

const HiddenDialogs = loadable(
  () =>
    import(
      /* webpackChunkName: "HiddenDialogs" */ 'components/sections/MyProfile/BlockAndHide/HiddenDialogs'
    ),
  {
    resolveComponent: (component) => component.HiddenDialogs,
  }
);

const HelpCenter = loadable(
  () =>
    import(
      /* webpackChunkName: "HelpCenter" */ 'components/sections/MyProfile/HelpCenter'
    ),
  {
    resolveComponent: (component) => component.HelpCenter,
  }
);

const ContactUs = loadable(
  () =>
    import(
      /* webpackChunkName: "ContactUs" */ 'components/sections/MyProfile/ContactUs'
    ),
  {
    resolveComponent: (component) => component.ContactUs,
  }
);

const LandingRoutes = loadable(
  () => import(/* webpackChunkName: "LandingRoutes" */ 'pages/LandingRoutes'),
  {
    resolveComponent: (component) => component.LandingRoutes,
  }
);

const WizardRoutes = loadable(
  () => import(/* webpackChunkName: "WizardRoutes" */ 'pages/WizardRoutes'),
  {
    resolveComponent: (component) => component.WizardRoutes,
  }
);

const CheckInboxPage = loadable(
  () => import(/* webpackChunkName: "CheckInboxPage" */ 'pages/CheckInboxPage'),
  {
    resolveComponent: (component) => component.CheckInboxPage,
  }
);

const ForgotPasswordPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ForgotPasswordPage" */ 'pages/ForgotPasswordPage'
    ),
  {
    resolveComponent: (component) => component.ForgotPasswordPage,
  }
);

const NotFoundPage = loadable(
  () => import(/* webpackChunkName: "NotFoundPage" */ 'pages/NotFoundPage'),
  {
    resolveComponent: (component) => component.NotFoundPage,
  }
);

const ChatPage = loadable(
  () => import(/* webpackChunkName: "ChatPage" */ 'pages/ChatPage'),
  {
    resolveComponent: (component) => component.ChatPage,
  }
);

const NewPasswordPage = loadable(
  () =>
    import(/* webpackChunkName: "NewPasswordPage" */ 'pages/NewPasswordPage'),
  {
    resolveComponent: (component) => component.NewPasswordPage,
  }
);

const LegalPage = loadable(
  () => import(/* webpackChunkName: "LegalPage" */ 'pages/LegalPage'),
  {
    resolveComponent: (component) => component.LegalPage,
  }
);

const UserProfilePage = loadable(
  () =>
    import(/* webpackChunkName: "UserProfilePage" */ 'pages/UserProfilePage'),
  {
    resolveComponent: (component) => component.UserProfilePage,
  }
);

const EditProfilePage = loadable(
  () =>
    import(/* webpackChunkName: "EditProfilePage" */ 'pages/EditProfilePage'),
  {
    resolveComponent: (component) => component.EditProfilePage,
  }
);

const FinderPage = loadable(
  () => import(/* webpackChunkName: "FinderPage" */ 'pages/FinderPage'),
  {
    resolveComponent: (component) => component.FinderPage,
  }
);

const MessengerPage = loadable(
  () => import(/* webpackChunkName: "MessengerPage" */ 'pages/MessengerPage'),
  {
    resolveComponent: (component) => component.MessengerPage,
  }
);

const PresentsGuidePage = loadable(
  () =>
    import(
      /* webpackChunkName: "PresentsGuidePage" */ 'pages/PresentsGuidePage'
    ),
  {
    resolveComponent: (component) => component.PresentsGuidePage,
  }
);

const PresentsPage = loadable(
  () => import(/* webpackChunkName: "PresentsPage" */ 'pages/PresentsPage'),
  {
    resolveComponent: (component) => component.PresentsPage,
  }
);

const MailsChainsPagePage = loadable(
  () =>
    import(/* webpackChunkName: "MailsChatsPage" */ 'pages/MailsChainsPage'),
  {
    resolveComponent: (component) => component.MailsChatsPage,
  }
);

const MailsChatPage = loadable(
  () => import(/* webpackChunkName: "MailsChatPage" */ 'pages/MailsChatPage'),
  {
    resolveComponent: (component) => component.MailsChatPage,
  }
);

const MyProfilePage = loadable(
  () => import(/* webpackChunkName: "MyProfilePage" */ 'pages/MyProfilePage'),
  {
    resolveComponent: (component) => component.MyProfilePage,
  }
);

const UpgradeBalancePage = loadable(
  () =>
    import(
      /* webpackChunkName: "UpgradeBalancePage" */ 'pages/UpgradeBalancePage'
    ),
  {
    resolveComponent: (component) => component.UpgradeBalancePage,
  }
);

const UpgradeSubscriptionPage = loadable(
  () =>
    import(
      /* webpackChunkName: "UpgradeSubscriptionPage" */ 'pages/UpgradeSubscriptionPage'
    ),
  {
    resolveComponent: (component) => component.UpgradeSubscriptionPage,
  }
);

const PaymentPage = loadable(
  () => import(/* webpackChunkName: "PaymentPage" */ 'pages/PaymentPage'),
  {
    resolveComponent: (component) => component.PaymentPage,
  }
);

const PaymentOneClickPage = loadable(
  () =>
    import(
      /* webpackChunkName: "PaymentOneClickPage" */ 'pages/PaymentOneClickPage'
    ),
  {
    resolveComponent: (component) => component.PaymentOneClickPage,
  }
);

const PaymentSuccessPage = loadable(
  () =>
    import(
      /* webpackChunkName: "PaymentSuccessPage" */ 'pages/PaymentSuccessPage'
    ),
  {
    resolveComponent: (component) => component.PaymentSuccessPage,
  }
);

const PaymentFailPage = loadable(
  () =>
    import(/* webpackChunkName: "PaymentFailPage" */ 'pages/PaymentFailPage'),
  {
    resolveComponent: (component) => component.PaymentFailPage,
  }
);

const LoginPage = loadable(
  () => import(/* webpackChunkName: "LoginPage" */ 'pages/LoginPage'),
  {
    resolveComponent: (component) => component.LoginPage,
  }
);

const IndexPage = loadable(
  () => import(/* webpackChunkName: "IndexPage" */ 'pages/IndexPage'),
  {
    resolveComponent: (component) => component.IndexPage,
  }
);

const DataPrivacyPage = loadable(
  () =>
    import(/* webpackChunkName: "DataPrivacyPage" */ 'pages/DataPrivacyPage'),
  {
    resolveComponent: (components) => components.DataPrivacyPage,
  }
);

const CookieSettings = loadable(
  () => import(/* webpackChunkName: "CookieSettings" */ 'pages/CookieSettings'),
  {
    resolveComponent: (components) => components.CookieSettings,
  }
);

export const Routes = () => {
  const { isMobile } = useMedia();

  return (
    <Switch>
      <Route path="/landing" component={LandingRoutes} />
      <Route path="/wizard" component={WizardRoutes} />

      <AppRoute path="/login" component={LoginPage} isPublic exact />

      <AppRoute path="/terms" component={LegalPage} exact />
      <AppRoute path="/disclosures-disclaimers" component={LegalPage} exact />
      <AppRoute
        path="/(privacy|refund|tracking|anti-scam)-policy"
        component={LegalPage}
        exact
      />
      <AppRoute path="/data-privacy" component={DataPrivacyPage} exact />
      <AppRoute path="/cookie-settings" component={CookieSettings} exact />
      <AppRoute path="/forgot-password" component={ForgotPasswordPage} exact />
      <AppRoute path="/new-password/:code" component={NewPasswordPage} exact />

      <AppRoute path="/" component={IndexPage} isPublic exact />
      <AppRoute path="/finder" component={FinderPage} isPrivate exact />

      <AppRoute path="/my-profile" component={MyProfilePage} isPrivate exact />
      <AppRoute
        path="/my-profile/edit"
        component={EditProfilePage}
        isPrivate
        exact
      />
      <AppRoute
        path="/my-profile/contact-us"
        component={ContactUs}
        isPrivate
        exact
      />
      <AppRoute
        path="/my-profile/help-center"
        component={HelpCenter}
        isPrivate
        exact
      />
      <AppRoute
        path="/my-profile/settings/account/change-email"
        component={ChangeEmail}
        isPrivate
        exact
      />
      <AppRoute
        path="/my-profile/settings/account/change-password"
        component={ChangePassword}
        isPrivate
        exact
      />
      <AppRoute
        path="/my-profile/settings/account"
        component={AccountSettings}
        isPrivate
        exact
      />
      <AppRoute
        path="/my-profile/settings/notifications"
        component={NotificationsSettings}
        isPrivate
        exact
      />
      <AppRoute
        path="/my-profile/settings"
        component={SettingsMobile}
        isPrivate
        exact
      />
      <AppRoute
        path="/my-profile/settings/delete"
        component={DeleteAccount}
        isPrivate
        exact
      />

      <AppRoute
        path={Pages.BlockedProfiles}
        component={BlockedProfile}
        isPrivate
        exact
      />

      <AppRoute
        path={Pages.BlockedAndHidden}
        component={BlockedAndHiddenProfile}
        isPrivate
        exact
      />

      <AppRoute
        path={Pages.HiddenDialogs}
        component={HiddenDialogs}
        isPrivate
        exact
      />

      <AppRoute
        path="/check-inbox"
        component={CheckInboxPage}
        isPrivate
        exact
      />

      <AppRoute
        path={Pages.Dialogs}
        component={MessengerPage}
        isPrivate
        exact
      />
      <AppRoute
        path="/dialogs/:contactId"
        component={isMobile ? ChatPage : MessengerPage}
        isPrivate
      />

      <AppRoute
        path="/presents/guide/:contactId"
        component={PresentsGuidePage}
        isPrivate
        exact
      />
      <AppRoute
        path="/presents/send/:contactId"
        component={PresentsPage}
        isPrivate
      />

      <AppRoute path="/mails" component={MailsChainsPagePage} isPrivate exact />
      <AppRoute path="/mails/:contactId" component={MailsChatPage} isPrivate />

      <AppRoute
        path="/upgrade/balance"
        component={UpgradeBalancePage}
        isPrivate
        exact
      />
      <AppRoute
        path="/upgrade/subscription"
        component={UpgradeSubscriptionPage}
        isPrivate
        exact
      />

      <AppRoute path="/payment/fail" component={PaymentFailPage} exact />
      <AppRoute path="/payment/success" component={PaymentSuccessPage} exact />
      <AppRoute
        path="/payment/card/:packageId"
        component={PaymentPage}
        isPrivate
        exact
      />
      <AppRoute
        path="/payment/one-click/:packageId"
        component={PaymentOneClickPage}
        isPrivate
        exact
      />

      <AppRoute
        path="/user/:contactId"
        component={UserProfilePage}
        isPrivate
        exact
      />

      <AppRoute path="*" component={NotFoundPage} />
    </Switch>
  );
};
