import { useCallback } from 'react';

import { TrackingApi } from 'api/TrackingApi';

export enum ToastActionName {
  Show = 'show',
  Close = 'close',
  Click = 'click',
}

export const useToastTracking = () => {
  const trackToast = useCallback(
    ({
      action,
      senderId,
      notificationId,
      notificationTitle,
      notificationDescription,
      uniqueId,
      messageSharedId,
      inmailSharedId,
      sympathyId,
      userVisitId,
    }: {
      action: ToastActionName;
      senderId: string;
      uniqueId: string | null;
      notificationId: number;
      notificationTitle: string;
      notificationDescription: string;
      messageSharedId: number | null;
      inmailSharedId: number | null;
      sympathyId: number | null;
      userVisitId: number | null;
    }) => {
      TrackingApi.trackToast({
        action,
        unique_id: uniqueId,
        sender_ulid: senderId,
        notification_id: notificationId,
        notification_title: notificationTitle,
        notification_description: notificationDescription,
        message_shared_id: messageSharedId,
        inmail_shared_id: inmailSharedId,
        sympathy_id: sympathyId,
        user_visit_id: userVisitId,
        url: window.location.href,
      }).catch();
    },
    []
  );

  return { trackToast };
};
