import { AppThunk } from 'store';

import { AuthApi } from 'api/AuthApi';
import { resetState as resetCommonState } from 'store/common/commonSlice';
import { resetState as resetFinderState } from 'store/finder/finderSlice';
import { resetState as resetMailsState } from 'store/mails/mailsSlice';
import { resetState as resetMessengerState } from 'store/messenger/messengerSlice';
import { resetState as resetNotificationsState } from 'store/notifications/notificationsSlice';
import { resetState as resetProfileState } from 'store/profile/profileSlice';
import { resetState as resetSystemPopupState } from 'store/systemPopup/systemPopupSlice';

import { setAuthLoading, setIsAuthenticated, setUser } from './authSlice';

export const loginThunk =
  (payload: { email: string; password: string }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));

      const { access_token, expires_in } = await AuthApi.login(payload);

      dispatch(
        setIsAuthenticated({
          accessToken: access_token,
          expire: expires_in,
          isAuthenticated: true,
        })
      );
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

export const logoutThunk =
  ({ withApi }: { withApi: boolean }): AppThunk =>
  async (dispatch) => {
    try {
      if (withApi) await AuthApi.logout();
    } finally {
      dispatch(setUser(null));
      dispatch(
        setIsAuthenticated({
          accessToken: null,
          expire: null,
          isAuthenticated: false,
        })
      );

      dispatch(resetCommonState());
      dispatch(resetFinderState());
      dispatch(resetMailsState());
      dispatch(resetMessengerState());
      dispatch(resetNotificationsState());
      dispatch(resetProfileState());
      dispatch(resetSystemPopupState());
    }
  };
