import { AppDispatch, AppThunk } from 'store';

import { Mail } from 'types/interfaces/mails/Mail';
import { MailsChain } from 'types/interfaces/mails/MailsChain';
import { MailsChainsFilters } from 'types/interfaces/mails/MailsChainsFilters';
import { UserContact } from 'types/interfaces/user/UserContact';

import { MailsApi } from 'api/MailsApi';
import { Funnel } from 'helpers/funnels/funnels';
import {
  setActiveSystemPopup,
  SystemPopupTypes,
} from 'store/systemPopup/systemPopupSlice';

import {
  addMail,
  addMails,
  addMailsChains,
  grantAccessToMail,
  setLoadingMails,
  setMails,
  setMailsChains,
  setMailsChainsLoading,
  setMailsInitLoading,
  setNextMailsChains,
  updateMailsChainWithNewMail,
} from './mailsSlice';

export const fetchMailsChainsThunk =
  (filters: MailsChainsFilters): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setMailsChainsLoading(true));

    const { data: mailsChains, next } =
      await MailsApi.fetchMailsChains(filters);

    dispatch(setNextMailsChains(next));
    dispatch(setMailsChains(mailsChains));
    dispatch(setMailsChainsLoading(false));
  };

export const fetchMoreMailsChainsThunk =
  (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { inbox } = getState();
    const nextUrl = inbox.nextMailsChains;

    if (!nextUrl) return;

    const { data: mailsChains, next } =
      await MailsApi.fetchMoreMailsChains(nextUrl);

    dispatch(addMailsChains(mailsChains));
    dispatch(setNextMailsChains(next));
  };

export const refetchMailsChainsThunk =
  (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { mailsFilters } = getState().inbox;
    const { data: mailsChains } = await MailsApi.fetchMailsChains(mailsFilters);

    dispatch(setMailsChains(mailsChains));
  };

export const fetchMailsThunk =
  (payload: { contactId: string }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setMailsInitLoading(true));

    const mailsChainResponse = await MailsApi.fetchMails(payload.contactId);

    dispatch(
      setMails({
        contact: mailsChainResponse.contact,
        mails: mailsChainResponse.inmails,
        next: mailsChainResponse.next,
        isLiked: mailsChainResponse.is_liked,
        isEnabledPresents: mailsChainResponse.is_real_gift_available,
      })
    );
    dispatch(setMailsInitLoading(false));
  };

export const fetchMoreMailsThunk =
  (payload: { contactId: string }): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { contactId } = payload;
    const { inbox } = getState();

    const nextUrl = inbox.mails[contactId].next;

    if (!nextUrl) return;

    const mailsChainResponse = await MailsApi.fetchMoreMails(nextUrl);

    dispatch(
      addMails({
        mails: mailsChainResponse.inmails,
        contactId,
        next: mailsChainResponse.next,
      })
    );
  };

export const sendTextMailThunk =
  (payload: {
    contact: UserContact;
    body: string;
    photosIds: number[];
  }): AppThunk =>
  async () => {
    await MailsApi.sendTextMail({
      contactId: payload.contact.ulid_id,
      body: payload.body,
      photosIds: payload.photosIds,
    });
  };

export const addNewMailFromSocketThunk =
  (payload: { message: Mail; contactId: string }): AppThunk =>
  (dispatch: AppDispatch, getState) => {
    const { mailsChains } = getState().inbox;

    dispatch(addMail(payload));

    const hasDialogWith = (contactId: string) => (mailsChain: MailsChain) =>
      mailsChain.contact.ulid_id === contactId;

    if (mailsChains.some(hasDialogWith(payload.contactId))) {
      dispatch(updateMailsChainWithNewMail(payload));
    } else {
      dispatch(refetchMailsChainsThunk());
    }
  };

export const unlockMailMessageThunk =
  (payload: {
    contactId: string;
    photoUrl: string;
    mailId: number;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoadingMails(payload.mailId));

    try {
      const { data } = await MailsApi.getMailAccess({ mailId: payload.mailId });

      if (data.data?.paid) {
        dispatch(
          grantAccessToMail({
            mailId: payload.mailId,
            contactId: payload.contactId,
          })
        );
      }

      if (!data.data?.paid) {
        dispatch(
          setActiveSystemPopup({
            type: SystemPopupTypes.LowBalance,
            params: {
              photoUrl: payload?.photoUrl,
              url: `/mails/${payload.contactId}`,
              type: 'mailRead',
              funnel: Funnel.MailRead,
              contactId: payload.contactId,
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        setActiveSystemPopup({
          type: SystemPopupTypes.LowBalance,
          params: {
            photoUrl: payload?.photoUrl,
            url: `/mails/${payload.contactId}`,
            type: 'mailRead',
            funnel: Funnel.MailRead,
            contactId: payload.contactId,
          },
        })
      );
    }

    dispatch(setLoadingMails(payload.mailId));
  };
