import { SystemPopupTypes } from 'store/systemPopup/systemPopupSlice';

import { InitCreditsPopup } from './submodules/InitCreditsPopup';
import { LowBalancePopup } from './submodules/LowBalancePopup';
import { MailConfirmProfilePopup } from './submodules/MailConfirmProfilePopup';
import { MailConfirmRegPopup } from './submodules/MailConfirmRegPopup';
import { PresentSentPopup } from './submodules/PresentSentPopup';
import { PromotePWAPopup } from './submodules/PromotePWAPopup';
import { ReportUserPopup } from './submodules/ReportUserPopup';
import { RestartSessionPopup } from './submodules/RestartSessionPopup';

export const SYSTEM_POPUPS_MAP = {
  [SystemPopupTypes.LowBalance]: LowBalancePopup,
  [SystemPopupTypes.InitCredits]: InitCreditsPopup,
  [SystemPopupTypes.MailConfirmAfterReg]: MailConfirmRegPopup,
  [SystemPopupTypes.ReportUser]: ReportUserPopup,
  [SystemPopupTypes.PresentSent]: PresentSentPopup,
  [SystemPopupTypes.PromotePWA]: PromotePWAPopup,
  [SystemPopupTypes.MailConfirmAfterProfile]: MailConfirmProfilePopup,
  [SystemPopupTypes.OnlineOnClick]: RestartSessionPopup,
};
