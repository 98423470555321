import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NotificationInfoByType } from 'types/interfaces/Notifications';
import { UserCard } from 'types/interfaces/user/UserCard';

import { FinderFilters } from 'api/FinderApi';
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from 'helpers/sessionStorage';

export const FINDER_FILTERS = 'finderFilters';

const initFinderFilters = () => {
  try {
    const filtersFromSessionStorage = getSessionStorageItem(FINDER_FILTERS);

    if (!filtersFromSessionStorage) {
      return null;
    }

    return JSON.parse(filtersFromSessionStorage);
  } catch (error) {
    return null;
  }
};

type FinderState = {
  users: UserCard[];
  filters: FinderFilters | null;
  next: string | null;
  sympathies: NotificationInfoByType | null;
  loading: boolean;
  initLoading: boolean;
};

const initialState: FinderState = {
  users: [],
  filters: initFinderFilters(),
  next: null,
  sympathies: null,
  loading: false,
  initLoading: true,
};

const finderSlice = createSlice({
  name: 'finder',
  initialState,
  reducers: {
    addUsers(
      state,
      action: PayloadAction<{ users: UserCard[]; packHash: string }>
    ) {
      const { users } = action.payload;
      const { packHash } = action.payload;

      const updatedUser = users.map((user) => {
        return { ...user, profiles_pack_hash: packHash };
      });

      state.users = [...state.users, ...updatedUser];
    },

    setUsers(
      state,
      action: PayloadAction<{ users: UserCard[]; packHash: string }>
    ) {
      const { users } = action.payload;
      const { packHash } = action.payload;
      const updatedUser = users.map((user) => {
        return { ...user, profiles_pack_hash: packHash };
      });
      state.users = updatedUser;
    },

    removeFinderUser(state, action: PayloadAction<{ usersId: string }>) {
      state.users = state.users.filter(
        (userItem) => userItem.ulid_id !== action.payload.usersId
      );
    },

    likeUser(state, action: PayloadAction<string>) {
      const { payload: userId } = action;

      state.users = state.users.map((user) =>
        user.ulid_id === userId ? { ...user, liked: true } : user
      );
    },

    setFilters(state, action: PayloadAction<FinderFilters | null>) {
      state.filters = action.payload;

      if (action.payload) {
        setSessionStorageItem(FINDER_FILTERS, JSON.stringify(action.payload));
      } else {
        removeSessionStorageItem(FINDER_FILTERS);
      }
    },

    setNext(state, action: PayloadAction<string | null>) {
      state.next = action.payload;
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setInitLoading(state, action: PayloadAction<boolean>) {
      state.initLoading = action.payload;
    },

    setSympathies(state, action: PayloadAction<NotificationInfoByType>) {
      state.sympathies = action.payload;
    },

    resetState() {
      return initialState;
    },
  },
});

export const {
  addUsers,
  setUsers,
  removeFinderUser,
  likeUser,
  setFilters,
  setNext,
  setLoading,
  setInitLoading,
  setSympathies,

  resetState,
} = finderSlice.actions;

export const finder = finderSlice.reducer;
