export enum Pages {
  Home = '/',
  MyProfile = '/my-profile',
  Terms = '/terms',
  PrivacyPolicy = '/privacy-policy',
  RefundPolicy = '/refund-policy',
  DisclosuresDisclaimers = '/disclosures-disclaimers',
  AntiScamPolicy = '/anti-scam-policy',
  TrackingPolicy = '/tracking-policy',
  HiddenDialogs = '/my-profile/hidden',
  BlockedProfiles = '/my-profile/blocked',
  BlockedAndHidden = '/my-profile/blocked-and-hidden',
  Dialogs = '/dialogs',
  Mails = '/mails',
  Finder = '/finder',
}
