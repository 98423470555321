import { MirrorService } from 'services/MirrorService';
import {
  ConversionEventType,
  FacebookConversionEventName,
  GtmConversionEventName,
} from 'types/enums/ConversionEvent';

import { ConversionsApi } from 'api/ConversionsApi';

export const Gtm = {
  init(params: { gtmId: string }) {
    const { gtmId } = params;

    if (!gtmId) {
      return;
    }

    const innerScript = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    const noscriptInner = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    const script = document.createElement('script');
    const noscript = document.createElement('noscript');

    document.head.insertBefore(script, document.head.childNodes[0]);
    document.body.insertBefore(noscript, document.body.childNodes[0]);

    script.innerHTML = innerScript;
    noscript.innerHTML = noscriptInner;
  },

  sendEvent(payload: { event: GtmConversionEventName; isRetry: boolean }) {
    window.dataLayer = window?.dataLayer || [];
    window.dataLayer?.push(payload);

    ConversionsApi.forceGtmConversion({
      eventName: payload.event,
      isRetry: payload.isRetry,
    });
  },

  userRegEvent(isRetry: boolean = false) {
    this.sendEvent({ event: GtmConversionEventName.Registration, isRetry });
  },

  userMailConfirm(isRetry: boolean = false) {
    this.sendEvent({ event: GtmConversionEventName.MailConfirm, isRetry });
  },

  userSale(isRetry: boolean = false) {
    this.sendEvent({ event: GtmConversionEventName.Sale, isRetry });
  },

  userResale(isRetry: boolean = false) {
    this.sendEvent({ event: GtmConversionEventName.Resale, isRetry });
  },

  userCardSubmit(isRetry: boolean = false) {
    this.sendEvent({ event: GtmConversionEventName.CardSubmit, isRetry });
  },

  userSpendFirstCredit(isRetry: boolean = false) {
    this.sendEvent({ event: GtmConversionEventName.SpendFirstCredit, isRetry });
  },

  userSpend15Credits(isRetry: boolean = false) {
    this.sendEvent({ event: GtmConversionEventName.Spend15Credits, isRetry });
  },

  engagedUserV1(isRetry: boolean = false) {
    this.sendEvent({ event: GtmConversionEventName.EngagedUserV1, isRetry });
  },

  engagedUserRetention(isRetry: boolean = false) {
    this.sendEvent({ event: GtmConversionEventName.EURetention, isRetry });
  },

  engagedUser30min(isRetry: boolean = false) {
    this.sendEvent({ event: GtmConversionEventName.EU30min, isRetry });
  },

  engagedUserSubmit(isRetry: boolean = false) {
    this.sendEvent({ event: GtmConversionEventName.EUSubmit, isRetry });
  },

  trackConversionEventByName(eventData: any) {
    const eventName = eventData?.event;

    if (!MirrorService.gtmId) return;

    switch (eventName) {
      case FacebookConversionEventName.CompleteRegistration: {
        this.userRegEvent();

        break;
      }
      case FacebookConversionEventName.AddToWishlist: {
        this.userMailConfirm();

        break;
      }
      case FacebookConversionEventName.Purchase: {
        this.userSale();

        break;
      }
      case FacebookConversionEventName.FindLocation: {
        this.userCardSubmit();

        break;
      }
      case FacebookConversionEventName.UserSpent1cr: {
        this.userSpendFirstCredit();

        break;
      }
      case FacebookConversionEventName.UserSpent15cr: {
        this.userSpend15Credits();

        break;
      }
      case FacebookConversionEventName.EngagedUserV1: {
        this.engagedUserV1();

        break;
      }
      case FacebookConversionEventName.EURetention: {
        this.engagedUserRetention();

        break;
      }
      case FacebookConversionEventName.EU30min: {
        this.engagedUser30min();

        break;
      }
      case FacebookConversionEventName.EUSubmit: {
        this.engagedUserSubmit();

        break;
      }

      default:
        break;
    }
  },

  trackConversionEventByType(payload: {
    eventType: ConversionEventType;
    isRetry: boolean;
  }) {
    if (!MirrorService.gtmId) return;

    switch (payload.eventType) {
      case ConversionEventType.Registration: {
        this.userRegEvent(payload.isRetry);

        break;
      }
      case ConversionEventType.MailConfirm: {
        this.userMailConfirm(payload.isRetry);

        break;
      }
      case ConversionEventType.FirstPurchase: {
        this.userSale(payload.isRetry);

        break;
      }
      case ConversionEventType.FindLocation: {
        this.userCardSubmit(payload.isRetry);

        break;
      }
      case ConversionEventType.UserSpent1cr: {
        this.userSpendFirstCredit(payload.isRetry);

        break;
      }
      case ConversionEventType.UserSpent15cr: {
        this.userSpend15Credits(payload.isRetry);

        break;
      }
      case ConversionEventType.EngagedUserV1: {
        this.engagedUserV1(payload.isRetry);

        break;
      }
      case ConversionEventType.EURetention: {
        this.engagedUserRetention(payload.isRetry);

        break;
      }
      case ConversionEventType.EU30min: {
        this.engagedUser30min(payload.isRetry);

        break;
      }
      case ConversionEventType.EUSubmit: {
        this.engagedUserSubmit(payload.isRetry);

        break;
      }

      default:
        break;
    }
  },
};
